.Customizable {
  background: #000000;
  padding: 247px 88px 247px 160px;
  .Customizable-main {
    display: flex;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    .left {
      width: 50%;
      position: relative;
      .left-back {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        width: 100%;
        background: rgba(157, 48, 208, 0.25);
        filter: blur(250px);
        z-index: 99;
        width: 100%;
      }
      h1 {
        margin: 0px 0px 36px 0px;
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 70px;
        /* identical to box height */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: 0.1em;

        /* Surface */

        color: #ffffff;
      }
    }
    .right {
      width: 50%;
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1600px) {
  .Customizable {
    .Customizable-main {
      .left {
        h1 {
          font-size: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .Customizable {
    padding: 100px 100px 150px 100px;
    .Customizable-main {
      .left {
        h1 {
          font-size: 35px;
          line-height: 50px;
          margin-bottom: 30px;
        }
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1155px) {
  .Customizable {
    .Customizable-main {
      .left {
        h1 {
          font-size: 30px;
          line-height: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Customizable {
    padding: 30px;
    .Customizable-main {
      flex-direction: column;
      .left {
        width: 100%;
        h1 {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .right {
        width: 100%;
        padding-top: 30px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Customizable {
    .Customizable-main {
      .left {
        width: 100%;
        h1 {
          font-size: 22px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
