.Referal {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  background-image: url("../../Images//robotBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.Referal .Referal-view-Referal {
  /* height: 30%; */
  width: 15.9375%;

  .show {
    transform: translateX(0);
  }
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );

  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
.Referal .Referal-view-two-one-button-Back {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
  display: none;
}
.Referal .Referal-view-two-one-button {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
}
.Referal .Referal-view-two-one-button-Back .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}
.Referal .Referal-view-two-one-button .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}
.Referal .Referal-view-main {
  width: 84.1725%;
  display: flex;
  flex-direction: column;
  padding: 0px 160px 164px 34px;

  /* Note: backdrop-filter has minimal browser support */
}

.Referal .Referal-view-main .Referal-view-main-first {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
  padding-bottom: 30px;
  /* Note: backdrop-filter has minimal browser support */
}

.Referal .Referal-view-main .Referal-view-main-first .rightDiv {
  display: flex;
  align-content: flex-end;
  align-self: flex-end;
  column-gap: 115px;
}
.Referal .Referal-view-main .Referal-view-main-first .rightDiv .nav-btn-one {
  padding: 8px 40px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
  border-radius: 7px;
  border: none;
}

.Referal .Referal-view-main .Referal-view-main-first .nav-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Referal .Referal-view-main .Referal-view-main-first .drawerIcons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none !important;
}

.Referal .Referal-view-main-first .nav-div-div {
  border-radius: 30px;
  margin-right: 8px;
}

.Referal .Referal-view-main-first .nav-div .nav-div-link-tag {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  text-decoration: none;
}

.Referal .Referal-view-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* Note: backdrop-filter has minimal browser support */
}

.Referal .Referal-view-main .Referal-view-main-second {
  /* height: 30%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 165px;
  padding-left: 20px;
  padding-right: 20px;
}

.Referal .Referal-view-main .Referal-view-main-second .heading {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 40px;
  border-radius: 7px;
  border: 1px solid #23a3ff;
}

.Referal .Referal-view-main .Referal-view-main-second .comingsoon {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  margin-top: 15px;
  border: 1px solid #23a3ff;
}
.Referal .Referal-view-main .Referal-view-main-second .comingsoon .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */
  letter-spacing: 0.1em;
  align-self: flex-start;
  margin-left: 50px;
  /* Surface */

  color: #ffffff;
}

.Referal .Referal-view-main .Referal-view-main-second .heading .text {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  margin: 0;
}

.Referal .Referal-view-main .Referal-view-main-second .comingsoon .linkDiv {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  border: 1px solid #23a3ff;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-top: 35px;
}

.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .linkDiv
  .textLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */

  text-align: center;
  letter-spacing: 0.1em;
  /* Surface */
  margin: 0;
  color: #ffffff;
}

.Referal .Referal-view-main .Referal-view-main-second .comingsoon .buttonDiv {
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 35px;
}

.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .buttonDiv
  .button {
  display: flex;
  background: #ffffff;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
  justify-content: center;
  width: 28%;
  border: none;
}
.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .buttonDiv
  .buttonTwo {
  display: flex;
  background: #1877f2;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
  justify-content: center;
  width: 28%;
  border: none;
}
.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .buttonDiv
  .buttonThree {
  display: flex;
  background: #60fc7c;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
  justify-content: center;
  width: 28%;
  border: none;
}
.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .buttonDiv
  .button
  .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.1em;

  /* Surface */
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 5px;
  color: #343434;
}
.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .buttonDiv
  .buttonTwo
  .textTwo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.1em;

  /* Surface */
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 5px;
  color: #ffffff;
}
.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .buttonDiv
  .buttonThree
  .textTwo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.1em;

  /* Surface */

  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 5px;
}

.Referal .Referal-view-main .Referal-view-main-second .comingsoon .block {
  width: 80%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .block
  .blockDiv {
  border: 1px solid #23a3ff;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding-top: 41px;
  padding-bottom: 42px;
  width: 24%;
  border-radius: 7px;
}

.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .block
  .blockDiv
  .textOne {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 24px;
  /* or 77% */
  margin: 0;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a1d3fb;
}
.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .block
  .blockDiv
  .textTwo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* or 126% */

  text-align: center;
  letter-spacing: 0.1em;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
  /* Surface */

  color: #ffffff;
}

.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .WithdrawButton {
  background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
  border-radius: 8px;
  width: 80%;
  margin-top: 58px;
  padding-top: 21px;
  padding-bottom: 21px;
  border: none;
}
.Referal
  .Referal-view-main
  .Referal-view-main-second
  .comingsoon
  .WithdrawButton
  .text {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  /* Surface */
  color: #ffffff;
  margin: 0;
}

@media screen and (max-width: 1150px) {
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .linkDiv
    .textLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.01em;
    /* Surface */
    margin: 0;
    color: #ffffff;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .button
    .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.01em;

    /* Surface */
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
    color: #343434;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonTwo
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.01em;

    /* Surface */
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
    color: #ffffff;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonThree
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.01em;

    /* Surface */

    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .block
    .blockDiv
    .textOne {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 24px;
    /* or 77% */
    margin: 0;
    text-align: center;
    letter-spacing: 0.1em;

    color: #a1d3fb;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .block
    .blockDiv
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* or 126% */

    text-align: center;
    letter-spacing: 0.1em;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* Surface */

    color: #ffffff;
  }
}

@media screen and (max-width: 910px) {
  .Referal .Referal-view-two-one-button-Back {
    /* height: 30%; */
    display: none;
  }

  .Referal .Referal-view-main .Referal-view-main-second .heading .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 114% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    margin: 0;
  }

  .Referal .Referal-view-main .Referal-view-main-second .comingsoon .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    /* identical to box height, or 126% */
    letter-spacing: 0.1em;
    align-self: flex-start;
    margin-left: 50px;
    /* Surface */
    color: #ffffff;
  }
  .Referal .Referal-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 126% */
    width: 80%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }

  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .button {
    display: flex;
    background: #ffffff;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
    justify-content: center;
    width: 30%;
    border: none;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonTwo {
    display: flex;
    background: #1877f2;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
    justify-content: center;
    width: 30%;
    border: none;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonThree {
    display: flex;
    background: #60fc7c;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
    justify-content: center;
    width: 30%;
    border: none;
  }

  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .linkDiv
    .textLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.01em;
    /* Surface */
    margin: 0;
    color: #ffffff;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .button
    .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.01em;

    /* Surface */
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
    color: #343434;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonTwo
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.01em;

    /* Surface */
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
    color: #ffffff;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonThree
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.01em;

    /* Surface */

    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .block
    .blockDiv
    .textOne {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 77% */
    margin: 0;
    text-align: center;
    letter-spacing: 0.1em;

    color: #a1d3fb;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .block
    .blockDiv
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    /* or 126% */

    text-align: center;
    letter-spacing: 0.1em;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* Surface */

    color: #ffffff;
  }
}

@media screen and (max-width: 850px) {
  .Referal .Referal-view-main .Referal-view-main-first .rightDiv {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-end;
    row-gap: 10px;
    justify-content: space-between;
  }

  .Referal .Referal-view-main .Referal-view-main-first .drawerIcons {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block !important;
  }

  .Referal .Referal-view-main {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    /* Note: backdrop-filter has minimal browser support */
  }
  .Referal .Referal-view-main .Referal-view-main-first {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
    /* Note: backdrop-filter has minimal browser support */
  }

  .Referal .Referal-view-main .Referal-view-main-second {
    /* height: 30%; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Referal .Referal-view-main .Referal-view-main-second .heading {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    padding-top: 23px;
    padding-bottom: 23px;

    border-radius: 7px;
  }

  .Referal .Referal-view-main .Referal-view-main-second .comingsoon {
    width: 100%;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-top: 15px;
  }
  .Referal .Referal-view-main .Referal-view-main-first .nav-div {
    width: 65%;
    display: flex;
    align-self: flex-end;
  }
  .Referal .Referal-view-main-first .nav-div .nav-div-link-tag {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    text-decoration: none;
  }
  .Referal .Referal-view-main .Referal-view-main-first .rightDiv .nav-btn-one {
    padding: 8px 0px;
    width: 50%;
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 100% */
    letter-spacing: 0.01em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
    border-radius: 7px;
    border: none;
    align-self: flex-end;
  }

  .Referal .Referal-view-Referal {
    /* height: 30%; */
    width: 50%;
    height: 60%;
    background-color: #000116;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    transition: 0.5s ease;
    transform: translateX(-1000px);
    z-index: 1;
  }

  .Referal .Referal-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 15px;
    /* identical to box height, or 126% */
    width: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }

  .Referal .Referal-view-two-one-button-Back .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 15px;
    /* identical to box height, or 126% */
    width: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }
  .Referal .Referal-view-two-one-button-Back {
    display: block;
  }

  .Referal .Referal-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 126% */
    width: 80%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }

  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .button {
    display: flex;
    background: #ffffff;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
    justify-content: center;
    width: 80%;
    border: none;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonTwo {
    display: flex;
    background: #1877f2;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: 10px;
    border: none;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonThree {
    display: flex;
    background: #60fc7c;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: 10px;
    border: none;
  }

  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .linkDiv
    .textLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 126% */

    text-align: left;
    letter-spacing: 0.01em;
    /* Surface */
    margin: 0;
    color: #ffffff;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .button
    .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.1em;

    /* Surface */
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
    color: #343434;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonTwo
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.1em;

    /* Surface */
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
    color: #ffffff;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .buttonDiv
    .buttonThree
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.1em;

    /* Surface */

    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .block
    .blockDiv
    .textOne {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 77% */
    margin: 0;
    text-align: center;
    letter-spacing: 0.1em;

    color: #a1d3fb;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .block
    .blockDiv
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 126% */

    text-align: center;
    letter-spacing: 0.01em;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* Surface */

    color: #ffffff;
  }
  .Referal .Referal-view-main .Referal-view-main-second .comingsoon .buttonDiv {
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 7px;
    padding-top: 17px;
    padding-bottom: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 35px;
    flex-direction: column;
  }
  .Referal .Referal-view-main .Referal-view-main-second .comingsoon .block {
    width: 98%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .block
    .blockDiv {
    border: 1px solid #23a3ff;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    padding-top: 41px;
    padding-bottom: 42px;
    width: 80%;
    margin-top: 10px;
    border-radius: 7px;
  }
}

@media screen and (max-width: 400px) {
  .Referal
    .Referal-view-main
    .Referal-view-main-second
    .comingsoon
    .linkDiv
    .textLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 20px;
    /* identical to box height, or 126% */

    text-align: left;
    letter-spacing: 0.01em;
    /* Surface */
    margin: 0;
    color: #ffffff;
  }
}
