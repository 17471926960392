.Navbar {
  background: transparent;
  position: relative;
  .navback {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow-x: hidden;
    z-index: -1;
  }
  .Navbar-main {
    padding: 27px 160px;

    .navbar-collapse {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      .navbar-nav {
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        column-gap: 60px;
        .nav-item {
          .nav-link {
            font-family: "Bakbak One";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            /* identical to box height, or 100% */

            letter-spacing: 0.1em;
            text-transform: uppercase;

            /* Surface */
            text-decoration: none;
            color: #ffffff;
            padding: 0px;
          }
        }
      }
      .nav-btn {
        float: right;
        padding: 11px 45px;
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height, or 100% */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Surface */

        color: #ffffff;
        background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
        border-radius: 7px;
        border: none;
      }
    }
  }
}
.container-fluid {
  padding: 0px;
}

// /////////////////////////Media Queries//////////////////////////////

@media only screen and (max-width: 1850px) {
  .Navbar {
    .Navbar-main {
      padding: 20px 160px;

      .navbar-collapse {
        .navbar-nav {
          margin: 0px auto;
          display: flex;
          justify-content: space-between;
          column-gap: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1685px) {
  .Navbar {
    .Navbar-main {
      padding: 20px 130px;

      .navbar-collapse {
        .navbar-nav {
          margin: 0px auto;
          display: flex;
          justify-content: space-between;
          column-gap: 30px;
          .nav-item {
            .nav-link {
              font-size: 20px;
              line-height: 20px;
            }
          }
        }
        .nav-btn {
          padding: 10px 40px;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .Navbar {
    .Navbar-main {
      padding: 20px 100px;

      .navbar-collapse {
        .navbar-nav {
          margin: 0px auto;
          display: flex;
          justify-content: space-between;
          column-gap: 30px;
          .nav-item {
            .nav-link {
              font-size: 18px;
              line-height: 18px;
            }
          }
        }
        .nav-btn {
          padding: 10px 40px;
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .Navbar {
    .Navbar-main {
      padding: 15px 60px;

      .navbar-collapse {
        .navbar-nav {
          margin: 0px auto;
          display: flex;
          justify-content: space-between;
          column-gap: 20px;
          .nav-item {
            .nav-link {
              font-size: 17px;
              line-height: 17px;
            }
          }
        }
        .nav-btn {
          padding: 10px 30px;
          font-size: 17px;
          line-height: 17px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Navbar {
    .navback {
      display: none;
    }
    .Navbar-main {
      padding: 0px 30px;

      .navbar-collapse {
        .navbar-nav {
          margin: 0px auto;
          display: flex;
          justify-content: space-between;
          row-gap: 10px;
          .nav-item {
            .nav-link {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
        .nav-btn {
          float: left;
          margin-top: 10px;
          padding: 10px 30px;
          font-size: 16px;
          line-height: 17px;
        }
      }
    }
  }
}

.nav-menu {
  &:focus {
    box-shadow: none !important;
    border: none;
  }
}
