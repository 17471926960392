.Main1 {
  background: #050519;
  padding: 114px 160px 222px 160px;
  .Main1-div {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 38%;
      p {
        margin: 40px 0px 0px 0px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .right {
      width: 62%;
      .video {
        padding: 3px;
        border-radius: 10px;
        background: linear-gradient(180deg, #77d6ff 0%, #cb45ff 100%);
      }
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1700px) {
  .Main1 {
    .Main1-div {
      .left {
        width: 50%;
        img {
          height: 50px;
          width: 60%;
          object-fit: contain;
        }
      }
      img {
        height: 50px;
        width: 60%;
        object-fit: contain;
      }
      .right {
        width: 50%;
      }
    }
  }
}

// @media only screen and (max-width: 1536px) {
//   .Main1 {
//     .Main1-div {
//       .left {
//         p {
//           font-size: 18px;
//           line-height: 22px;
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 1366px) {
  .Main1 {
    padding: 100px 100px 150px 100px;

    .Main1-div {
      .left {
        img {
          width: auto;
        }
        p {
          font-size: 18px;
          line-height: 22px;
          margin-top: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Main1 {
    padding: 30px;
    .Main1-div {
      flex-direction: column;
      .left {
        width: 100%;
        img {
          width: 70%;
        }
        p {
          font-size: 16px;
          line-height: 20px;
          margin-top: 10px;
        }
      }
      .right {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}
