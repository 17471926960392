.Social {
  background: #030416;
  padding: 100px 160px 316px 160px;
  .Social-main {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 50%;
      padding-top: 174px;
      background: url(/Images/social.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      h1 {
        margin: 0px 0px 36px 0px;
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 70px;
        /* identical to box height */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: 0.1em;

        /* Surface */

        color: #ffffff;
      }
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      padding-top: 174px;
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1600px) {
  .Social {
    .Social-main {
      .left {
        padding: 0px;
        h1 {
          font-size: 40px;
        }
      }
      .right {
        padding: 0px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .Social {
    padding: 100px 100px 150px 100px;
    .Social-main {
      .left {
        h1 {
          font-size: 35px;
          line-height: 50px;
          margin-bottom: 30px;
        }
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .Social {
    .Social-main {
      .left {
        h1 {
          font-size: 32px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Social {
    padding: 30px;
    .Social-main {
      flex-direction: column;
      .left {
        width: 100%;
        h1 {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .right {
        padding-top: 20px;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 393px) {
  .Social {
    .Social-main {
      .left {
        width: 100%;
        h1 {
          font-size: 23px;
          line-height: 30px;
        }
      }
    }
  }
}
