.dashboard {
  width: 100%;
  /* height: 100vh;
  overflow-x: hidden;
  overflow-y: auto; */
  position: relative;
  overflow: hidden;
  display: flex;
  background-image: url("../../Images//robotBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.dashboard .dashboard-view-dashboard {
  /* height: 30%; */
  width: 15.9375%;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );

  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  transition: transform 0.5s ease;
  .show {
    transform: translateX(0);
  }
}

.dashboard .dashboard-view-two-one-button {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
}
.dashboard .dashboard-view-two-one-button .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}
.dashboard .dashboard-view-main {
  width: 84.1725%;
  display: flex;
  flex-direction: column;
  padding: 48px 160px 240px 34px;

  /* Note: backdrop-filter has minimal browser support */
}

.dashboard .dashboard-view-main .dashboard-view-main-first .rightDiv {
  display: flex;
  align-content: flex-end;
  align-self: flex-end;
  column-gap: 115px;
}
.dashboard-view-main-first {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 50px; */
  /* Note: backdrop-filter has minimal browser support */
}

.dashboard
  .dashboard-view-main
  .dashboard-view-main-first
  .rightDiv
  .nav-btn-one {
  padding: 8px 40px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
  border-radius: 7px;
  border: none;
}

.dashboard .dashboard-view-main .dashboard-view-main-first .nav-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dashboard .dashboard-view-main .dashboard-view-main-first .drawerIcons {
  width: 30px;

  display: none !important;
}

.dashboard .dashboard-view-main-first .nav-div-div {
  border-radius: 30px;
  margin-right: 8px;
}

.dashboard .dashboard-view-main-first .nav-div .nav-div-link-tag {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  text-decoration: none;
}

.dashboard .dashboard-view-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* Note: backdrop-filter has minimal browser support */
}

.dashboard .dashboard-view-two-one-button-Back {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
  display: none;
}

.dashboard .dashboard-view-main .dashboard-view-main-second {
  /* height: 30%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  /* padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 150px; */
}

.dashboard .dashboard-view-two-two-first {
  /* height: 30%; */
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  column-gap: 16px;
}

.dashboard .dashboard-view-two-two-second {
  /* height: 30%; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
}

.dashboard .dashboard-view-two-two-first-first {
  /* height: 30%; */
  width: 60%;
  border: 1px solid #23a3ff;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  column-gap: 50px;
  border-radius: 7px;
  padding: 11px 114px 36px 37px;
}

.dashboard .dashboard-view-two-two-first-first .profile-pic {
  width: 105px;
  height: 105px;
  margin-top: auto;
  object-fit: cover;
}
.dashboard .dashboard-view-two-two-first-first .account {
  display: flex;
  flex-direction: column;
  /* margin: 0px 152px 0px 50px; */
}

.dashboard .dashboard-view-two-two-first-first .account .textHeading {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Surface */
  color: #ffffff;
  margin-bottom: 0px;
}

.dashboard .dashboard-view-two-two-first-first .account .textName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */
  letter-spacing: 0.1em;
  /* Surface */
  color: #ffffff;
  margin-bottom: 15px;
}
.dashboard .dashboard-view-two-two-first-first .account .textEmail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */
  margin-bottom: 15px;
  letter-spacing: 0.1em;
  /* Surface */
  color: #ffffff;
}
.dashboard .dashboard-view-two-two-first-first .account .content {
  margin-top: auto;
}
.dashboard .dashboard-view-two-two-first-first .account .textId {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */

  letter-spacing: 0.1em;
  /* Surface */
  margin-bottom: 0px;
  color: #ffffff;
}
.dashboard .dashboard-view-two-two-first-first .vipLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dashboard .dashboard-view-two-two-first-first .vipLogo .textHeading {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  margin-bottom: 0px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Surface */

  color: #ffffff;
}

.dashboard .dashboard-view-two-two-first-second {
  /* height: 30%; */
  width: 40%;
  border: 1px solid #23a3ff;

  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  padding-bottom: 24px;
}

.dashboard .dashboard-view-two-two-first-second .textHeading {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Surface */

  color: #ffffff;
  margin-bottom: 0px;
}

.dashboard .dashboard-view-two-two-first-second .textBalance {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */
  letter-spacing: 0.1em;
  margin: 15px 0px; /* Surface */

  color: #ffffff;
}
.dashboard .dashboard-view-two-two-first-second .textDigit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 126% */
  letter-spacing: 0.1em;
  /* Surface */
  margin: 0px;
  color: #ffffff;
}

.dashboard .dashboard-view-two-two-first-second .tokenDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 22px;
}
.dashboard .dashboard-view-two-two-first-second .tokenDivTwo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dashboard .dashboard-view-two-two-first-second .tokenDiv .textBalance {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */
  margin: 0px;
  letter-spacing: 0.1em;
  text-align: center;
  color: #a5d9ff;
}

.dashboard .dashboard-view-two-two-second-first {
  /* height: 30%; */
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 32px;
  border: 1px solid #23a3ff;

  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 7px;
}
.dashboard .dashboard-view-two-two-second-first .textOne {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  /* Surface */

  color: #ffffff;
}

.dashboard .dashboard-view-two-two-second-first .textTwo {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 114% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 42px;
  /* Surface */
  color: #ffffff;
}

.dashboard .dashboard-view-two-two-second-second {
  /* height: 30%; */
  width: 50%;
  border: 1px solid #23a3ff;
  padding-top: 21px;
  padding-bottom: 29px;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
}

.dashboard .dashboard-view-two-two-second-second .textFirst {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Surface */

  color: #ffffff;
}

.dashboard .dashboard-view-two-two-second-second .textSecond {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 126% */

  text-align: center;
  letter-spacing: 0.01em;

  /* Surface */
  margin-top: 17px;
  color: #ffffff;
}
.dashboard .dashboard-view-two-two-second-second .firstDiv {
  width: 75%;
  margin-top: 27px;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #23a3ff;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  padding-top: 17px;
  padding-bottom: 17px;
}
.dashboard
  .dashboard-view-main-second
  .dashboard-view-two-two-second-second
  .firstDiv
  .textFirst {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 126% */

  text-align: center;
  letter-spacing: 0.1em;
  margin: 0;
  /* Surface */
  width: 85%;
  color: #ffffff;
}

.dashboard .dashboard-view-two-two-second-second .secondDiv {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  margin-top: 40px;
  border: 1px solid #23a3ff;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 18px;
  padding-bottom: 14px;
}

.dashboard
  .dashboard-view-two-two-second-second
  .secondDiv
  .leftDiv
  .textFirst {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  /* identical to box height, or 96% */

  text-align: center;
  letter-spacing: 0.1em;
  margin: 0;
  color: #f0acfd;
}
.dashboard
  .dashboard-view-two-two-second-second
  .secondDiv
  .leftDiv
  .textSecond {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */

  text-align: center;
  letter-spacing: 0.1em;
  margin: 0;
  /* Surface */

  color: #ffffff;
}

.dashboard .dashboard-view-two-two-second-second .secondDiv .leftDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}
.dashboard
  .dashboard-view-two-two-second-second
  .secondDiv
  .rightDiv
  .nav-btn-one {
  padding: 8px 30px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
  border-radius: 7px;
  border: none;
  margin-right: 5px;
}
.dashboard .dashboard-view-two-two-second-second .thirdDiv {
  display: flex;
  width: 75%;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
}
.dashboard .dashboard-view-two-two-second-second .thirdDiv .leftDiv {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );

  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #23a3ff;
  border-radius: 7px;
  width: 48%;
  row-gap: 5px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.dashboard .dashboard-view-two-two-second-second .thirdDiv .leftDiv .textFirst {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  /* identical to box height, or 96% */
  margin: 0;
  text-align: center;
  letter-spacing: 0.1em;
  color: #f0acfd;
}

.dashboard
  .dashboard-view-two-two-second-second
  .thirdDiv
  .leftDiv
  .textSecond {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 126% */
  margin: 0;
  text-align: center;
  letter-spacing: 0.1em;
  /* Surface */

  color: #ffffff;
}

.dashboard .dashboard-view-two-two-second-second .thirdDiv .rightDiv {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #23a3ff;
  border-radius: 7px;
  width: 48%;
  padding-top: 11px;
  padding-bottom: 11px;
  row-gap: 5px;
}

.dashboard
  .dashboard-view-two-two-second-second
  .thirdDiv
  .rightDiv
  .textFirst {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  /* identical to box height, or 96% */

  text-align: center;
  letter-spacing: 0.1em;
  margin: 0;
  color: #f0acfd;
}

.dashboard
  .dashboard-view-two-two-second-second
  .thirdDiv
  .rightDiv
  .textSecond {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 126% */
  margin: 0;
  text-align: center;
  letter-spacing: 0.1em;

  /* Surface */

  color: #ffffff;
}
@media screen and (max-width: 1650px) {
  .dashboard .dashboard-view-main {
    padding: 50px 80px 100px 30px;
  }
  .dashboard .dashboard-view-two-two-first-first {
    display: flex;
    column-gap: 30px;
  }
}
@media screen and (max-width: 1480px) {
  .dashboard .dashboard-view-main .dashboard-view-main-first .rightDiv {
    column-gap: 30px;
  }
  .dashboard .dashboard-view-main-first .nav-btn-one {
    padding: 5px 45px;
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
    border-radius: 7px;
    border: none;
    margin-right: 30px;
  }
  .dashboard .dashboard-view-two-two-first-first .profile-pic {
    width: 80px;
    height: 80px;
    margin-top: auto;
    object-fit: cover;
  }
  .dashboard .dashboard-view-two-two-first-first .vipLogo .logoImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
  .dashboard .dashboard-view-two-two-second-second .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.1em;

    /* Surface */
    margin-top: 17px;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-second-second .firstDiv .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 18px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.1em;
    margin: 0;
    /* Surface */
    width: 85%;
    color: #ffffff;
  }
  .dashboard
    .dashboard-view-two-two-second-second
    .secondDiv
    .leftDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.1em;

    /* Surface */

    color: #ffffff;
  }

  .dashboard
    .dashboard-view-two-two-second-second
    .thirdDiv
    .leftDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* or 126% */

    text-align: center;
    letter-spacing: 0.1em;
    /* Surface */

    color: #ffffff;
  }
  .dashboard
    .dashboard-view-two-two-second-second
    .thirdDiv
    .rightDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* or 126% */

    text-align: center;
    letter-spacing: 0.1em;
    /* Surface */

    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-second .tokenDiv .textBalance {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */

    letter-spacing: 0.1em;
    text-align: center;
    color: #a5d9ff;
  }
}

@media screen and (max-width: 1300px) {
  .dashboard .dashboard-view-two-two-first-first .profile-pic {
    width: 70px;
    height: 70px;
    margin-top: auto;
    object-fit: cover;
  }
  .dashboard .dashboard-view-two-two-first-first .vipLogo .logoImage {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    /* identical to box height, or 126% */

    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 20px;
    text-align: left;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textEmail {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */
    margin-top: 8px;
    letter-spacing: 0.01em;
    /* Surface */
    text-align: left;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textId {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */
    text-align: left;
    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 8px;
    color: #ffffff;
  }
}
@media screen and (max-width: 1150px) {
  .dashboard .dashboard-view-two-two-first-first .picdiv {
    height: 15rem;
    width: 25%;
    display: flex;
    padding-top: 110px;
    padding-bottom: 20px;
    justify-content: center;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    /* identical to box height, or 126% */

    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 20px;
    text-align: left;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textEmail {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 126% */
    margin-top: 8px;
    letter-spacing: 0.01em;
    /* Surface */
    text-align: left;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textId {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 126% */
    text-align: left;
    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 8px;
    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-second-second .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 126% */
    padding: 0px 10px;
    text-align: center;
    letter-spacing: 0.1em;

    /* Surface */
    margin-top: 17px;
    color: #ffffff;
  }
  .dashboard
    .dashboard-view-main-second
    .dashboard-view-two-two-second-second
    .firstDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 18px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.01em;
    margin: 0;
    /* Surface */
    width: 85%;
    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-second-second .textFirst {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 114% */
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Surface */

    color: #ffffff;
  }
  .dashboard
    .dashboard-view-two-two-second-second
    .secondDiv
    .leftDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;
    margin-left: 1px;
    color: #f0acfd;
  }
}
@media screen and (max-width: 1030px) {
  .dashboard .dashboard-view-two-two-first-first {
    column-gap: 15px;
    padding: 11px 114px 36px 15px;
  }

  .dashboard .dashboard-view-two-two-first-first .profile-pic {
    width: 50px;
    height: 50px;
    margin-bottom: auto;
    margin-top: 50px;
  }

  .dashboard .dashboard-view-two-two-first-first .vipLogo .logoImage {
    width: 50px;
    height: 50px;
    margin-bottom: auto;
    margin-top: 20px;
  }

  .dashboard .dashboard-view-two-two-first-first .account .content {
    margin: 0;
    /* margin: 0px 152px 0px 50px; */
  }

  .dashboard .dashboard-view-two-two-first-first .account .textName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    /* identical to box height, or 126% */

    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 30px;
    text-align: left;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textEmail {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */
    margin-top: 8px;
    letter-spacing: 0.01em;
    /* Surface */
    text-align: left;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .textId {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */
    text-align: left;
    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 8px;
    color: #ffffff;
  }
}
@media screen and (max-width: 900px) {
  .dashboard .dashboard-view-main .dashboard-view-main-first .rightDiv {
    width: 60%;
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
  }
  .dashboard
    .dashboard-view-main
    .dashboard-view-main-first
    .rightDiv
    .nav-btn-one {
    padding: 2px 30px;
    width: 45%;
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
    border-radius: 7px;
    border: none;
    margin-right: 100px;
  }
  .dashboard .dashboard-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 126% */
    width: 80%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 10px;
  }
  .dashboard .dashboard-view-two-two-first-first {
    column-gap: 15px;
    padding: 11px 14px 36px 15px;
  }
  .dashboard .dashboard-view-two-two-first-second .textHeading {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 114% */
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
    /* Surface */

    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-first-second .textBalance {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */
    letter-spacing: 0.1em;
    margin-top: 15px;
    /* Surface */
    text-align: center;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-second .textDigit {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 126% */
    letter-spacing: 0.1em;
    /* Surface */

    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-first-second .tokenDiv {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard .dashboard-view-two-two-first-second .tokenDivTwo {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard .dashboard-view-two-two-second-first .textOne {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 114% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    /* Surface */
    padding-left: 3px;
    padding-right: 3px;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-second-second .textFirst {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 114% */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Surface */

    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-second-second .firstDiv {
    width: 95%;
    margin-top: 27px;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #23a3ff;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .dashboard .dashboard-view-two-two-second-second .firstDiv .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.01em;
    margin: 0;
    /* Surface */
    width: 85%;
    padding-right: 4px;
    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-second-second .secondDiv {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 7px;
    margin-top: 40px;
    border: 1px solid #23a3ff;
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
  }

  .dashboard
    .dashboard-view-two-two-second-second
    .secondDiv
    .leftDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;

    color: #f0acfd;
  }
  .dashboard
    .dashboard-view-two-two-second-second
    .secondDiv
    .leftDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.1em;

    /* Surface */

    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-second-second .secondDiv .leftDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dashboard
    .dashboard-view-two-two-second-second
    .secondDiv
    .rightDiv
    .nav-btn-one {
    padding: 8px 30px;
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
    border-radius: 7px;
    border: none;
  }
  .dashboard .dashboard-view-two-two-second-second .thirdDiv {
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-items: center;
    margin-top: 23px;
  }
  .dashboard .dashboard-view-two-two-second-second .thirdDiv .leftDiv {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #23a3ff;
    border-radius: 7px;
    width: 48%;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .dashboard
    .dashboard-view-two-two-second-second
    .thirdDiv
    .leftDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;
    color: #f0acfd;
  }

  .dashboard
    .dashboard-view-two-two-second-second
    .thirdDiv
    .leftDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    /* or 126% */

    text-align: center;
    letter-spacing: 0.1em;
    /* Surface */

    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-second-second .thirdDiv .rightDiv {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #23a3ff;
    border-radius: 7px;
    width: 48%;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .dashboard
    .dashboard-view-two-two-second-second
    .thirdDiv
    .rightDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;

    color: #f0acfd;
  }
  .dashboard
    .dashboard-view-two-two-second-second
    .thirdDiv
    .rightDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;

    color: #ffffff;
  }
}

@media screen and (max-width: 890px) {
  .dashboard .dashboard-view-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;

    /* Note: backdrop-filter has minimal browser support */
  }
  .dashboard .dashboard-view-main .dashboard-view-main-second {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-first {
    /* height: 30%; */
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-first
    .dashboard-view-two-two-first-second {
    /* height: 30%; */
    width: 95%;
    border: 1px solid #23a3ff;

    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 7px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 18px;
    padding-bottom: 24px;
    align-self: center;
    margin-top: 10px;
  }

  .dashboard .dashboard-view-main-second .dashboard-view-two-two-second {
    /* height: 30%; */
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 23px;
  }
  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-first {
    /* height: 30%; */
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 21px;
    padding-bottom: 32px;
    border: 1px solid #23a3ff;

    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    align-self: center;
    border-radius: 7px;
  }
  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second {
    /* height: 30%; */
    width: 95%;
    border: 1px solid #23a3ff;
    padding-top: 21px;
    padding-bottom: 29px;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    align-self: center;
    margin-top: 10px;
  }

  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second
    .thirdDiv
    .leftDiv {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #23a3ff;
    border-radius: 7px;
    width: 49%;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second
    .thirdDiv
    .leftDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;
    color: #f0acfd;
  }

  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second
    .thirdDiv
    .leftDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 350;
    font-size: 10px;
    line-height: 24px;
    /* or 126% */

    text-align: center;
    letter-spacing: 0.1em;
    /* Surface */

    color: #ffffff;
  }

  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second
    .thirdDiv
    .rightDiv {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #23a3ff;
    border-radius: 7px;
    width: 49%;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second
    .thirdDiv
    .rightDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;

    color: #f0acfd;
  }
  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second
    .thirdDiv
    .rightDiv
    .textSecond {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 350;
    font-size: 10px;
    line-height: 24px;
    /* identical to box height, or 96% */

    text-align: center;
    letter-spacing: 0.1em;

    color: #ffffff;
  }
  .dashboard
    .dashboard-view-main
    .dashboard-view-main-second
    .dashboard-view-two-two-second
    .dashboard-view-two-two-second-second
    .firstDiv
    .textFirst {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 11px;
    line-height: 15px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.01em;
    margin: 0;
    /* Surface */
    padding-right: 2px;
    color: #ffffff;
  }

  .dashboard .dashboard-view-two-two-first-first {
    width: 95%;
    column-gap: 10px;
    padding: 11px 30px 36px 15px;
    align-self: center;
    flex-direction: column;
    justify-content: center;
  }

  .dashboard .dashboard-view-two-two-first-first .profile-pic {
    width: 90px;
    height: 90px;
    align-self: center;
  }

  .dashboard .dashboard-view-two-two-first-first .vipLogo {
    flex-direction: column;
    margin-top: 15px;
  }
  .dashboard .dashboard-view-two-two-first-first .vipLogo .logoImage {
    width: 55px;
    height: 55px;
  }

  .dashboard .dashboard-view-two-two-first-first .account {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .dashboard .dashboard-view-two-two-first-first .account .textHeading {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }
  .dashboard .dashboard-view-two-two-first-first .vipLogo .textHeading {
    font-size: 16px;
    text-align: center;
  }
  .dashboard .dashboard-view-two-two-first-first .account .content .textName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
    /* identical to box height, or 126% */

    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 10px;
    text-align: center;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .content .textEmail {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height, or 126% */
    margin-top: 5px;
    letter-spacing: 0.01em;
    /* Surface */
    text-align: center;
    color: #ffffff;
  }
  .dashboard .dashboard-view-two-two-first-first .account .content .textId {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 10px;
    /* identical to box height, or 126% */
    text-align: center;
    letter-spacing: 0.1em;
    /* Surface */
    margin-top: 5px;
    color: #ffffff;
  }

  .dashboard .dashboard-view-one-second {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    /* Note: backdrop-filter has minimal browser support */
  }

  .dashboard .dashboard-view-main .dashboard-view-main-first {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  .dashboard .dashboard-view-main .dashboard-view-main-first .rightDiv {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-end;
    row-gap: 10px;
    justify-content: space-between;
  }
  .dashboard
    .dashboard-view-main
    .dashboard-view-main-first
    .rightDiv
    .nav-btn-one {
    padding: 8px 0px;
    width: 55%;
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
    border-radius: 7px;
    border: none;
    align-self: flex-end;
  }

  .dashboard .dashboard-view-main-first .nav-div .nav-div-link-tag {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    text-decoration: none;
  }
  .dashboard .dashboard-view-main .dashboard-view-main-first .drawerIcons {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block !important;
  }
  .dashboard .dashboard-view-main .dashboard-view-main-first .nav-div {
    width: 65%;
    display: flex;
    align-self: flex-end;
  }

  .dashboard .dashboard-view-dashboard {
    /* height: 30%; */
    /* width: 20%; */
    width: 50%;
    height: 60%;
    background-color: #000116;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    transition: 0.5s ease;
    transform: translateX(-1000px);
    z-index: 1;
  }
  .dashboard .dashboard-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    /* identical to box height, or 126% */
    width: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }

  .dashboard .dashboard-view-two-one-button-Back {
    display: block;
  }
}
