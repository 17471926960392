.drawer .drawer-view-drawer {
  /* height: 30%; */
  width: 15.9375%;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );

  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  transition: transform 0.5s ease;
  .show {
    transform: translateX(0);
  }
}

.drawer-view-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* Note: backdrop-filter has minimal browser support */
}

.drawer-view-two-one-button {
  /* height: 30%; */
  width: 100%;
  height: 70px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
}
.drawer-view-two-one-button .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}

@media screen and (max-width: 1200px) {
  .drawer-view-two-one-button {
    height: 60px;
  }
  .drawer-view-two-one-button .buttonText {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 126% */
    width: 80%;
    letter-spacing: 0.01em;
  }
}

@media screen and (max-width: 820px) {
  .drawer-view-two-one-button {
    height: 50px;
  }
  .drawer-view-two-one-button .buttonText {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 126% */
    width: 80%;
    letter-spacing: 0.01em;
  }
}
