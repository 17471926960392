.Features {
  padding: 180px 160px;
  background: #050519;
  .Features-main {
    h1 {
      margin: 0px 0px 95px 0px;
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 70px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .slider {
      position: relative;
      padding: 0px 150px;
      .slick-prev {
        &:before {
          content: url(/Images/prev.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          bottom: -70px;
          left: 100%;
          z-index: 999;
        }
      }
      .slick-next {
        &:before {
          content: url(/Images/next.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          bottom: -70px;
          right: 100%;
          z-index: 999;
        }
      }

      .card {
        display: flex !important;
        justify-content: center;
        background: transparent;
        .feature-card {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: auto;

          width: 389px;
          // height: 100%;
          padding: 20px;
          // padding: 41px 47px 16px 47px;
          background: linear-gradient(
            107.21deg,
            rgba(98, 114, 201, 0.26) 2.84%,
            rgba(163, 178, 254, 0.06) 73.23%
          );
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 4px 24px -1px rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(20px);
          /* Note: backdrop-filter has minimal browser support */
          border: 3px solid;
          border-image-outset: 0px;
          border-image-slice: 3%;
          border-image-repeat: stretch;
          border-image-source: linear-gradient(
            138.47deg,
            rgba(255, 125, 250, 0.56) 12.39%,
            rgba(253, 0, 243, 0.34) 48.48%,
            rgba(34, 162, 255, 0.35) 64.08%,
            rgba(34, 162, 255, 0.28) 76.52%
          );

          border-radius: 7px;
          .card-header {
            padding: 0px;
            display: flex;
            justify-content: center;
            img {
              object-fit: cover;
              width: 100%;
              height: 282px;
              border-radius: 8px;
            }
          }
          .card-body {
            padding: 0px;
            p {
              margin: 70px 0px 10px 0px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 36px;
              text-align: center;
              letter-spacing: 0.1em;

              /* Surface */

              color: #ffffff;
            }
            a {
              text-decoration: none;
              float: right;
              background: transparent;
              border: none;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 28px;
              line-height: 42px;
              text-align: center;
              letter-spacing: 0.1em;

              color: #78d3ff;
            }
          }
        }
      }
    }
  }
}

// /////////////////////////////// Media Queries///////////////////////////////

@media only screen and (max-width: 1850px) {
  .Features {
    padding: 100px;
    .Features-main {
      .slider {
        padding: 0px 100px;
        .card {
          .feature-card {
            padding: 20px;
            .card-header {
              img {
                object-fit: cover;
              }
            }
            .card-body {
              p {
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1650px) {
  .Features {
    padding: 100px;
    .Features-main {
      .slider {
        padding: 0px 100px;
        .card {
          .feature-card {
            padding: 20px 20px;
            width: 300px;
            height: 100%;
            .card-header {
              margin: auto;
              width: 250px;
              height: auto;
              img {
                width: 100%;
                object-fit: cover;
              }
            }
            .card-body {
              // width: 250px;
              p {
                margin-top: 10px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .Features {
    padding: 100px;
    .Features-main {
      .slider {
        padding: 0px 100px;
        .card {
          .feature-card {
            width: 390px;
            padding: 20px 30px;
            .card-header {
              width: 100%;
              height: 282px;
              img {
                object-fit: cover;
              }
            }
            .card-body {
              width: 100%;
              p {
                margin-top: 10px;
                font-size: 20px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1220px) {
  .Features {
    padding: 100px;
    .Features-main {
      .slider {
        padding: 0px 60px;
        .card {
          margin: auto;
          width: 300px !important;
          .feature-card {
            width: 300px;
            .card-header {
              width: 100%;
              height: 200px;
              padding: 0px;
              img {
                object-fit: cover;
              }
            }
            .card-body {
              padding: 0px;
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Features {
    padding: 30px;
    .Features-main {
      h1 {
        font-size: 31px;
        line-height: 35px;
        margin-bottom: 30px;
      }
      .slider {
        padding: 0px;
        .slick-prev {
          &:before {
            display: none;
            z-index: 999;
          }
        }
        .slick-next {
          &:before {
            display: none;
            z-index: 999;
          }
        }
        .card {
          .feature-card {
            padding: 10px 20px 16px 20px;
            margin: 0px;
            width: 300px;
            margin: 0px auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            .card-header {
              width: 250px;
              height: 250px;
              img {
                object-fit: cover;
                width: 250px;
                height: auto;
                margin: auto;
              }
            }
            .card-body {
              p {
                font-size: 16px;
                margin-top: 10px;
                line-height: 20px;
              }
              a {
                font-size: 18px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
