.Hero {
  position: relative;
  // height: 100vh;

  .back-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -2;
  }
  .Hero-main {
    z-index: 10;
    padding: 250px 0px;
    h1 {
      display: flex;
      justify-content: center;
      font-family: "Game Of Squids";
      font-style: normal;
      font-weight: 400;
      font-size: 75px;
      line-height: 75px;
      // line-height: 129px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #ffffff;
    }
    p {
      display: flex;
      justify-content: center;
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 400;
      font-size: 29px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.1em;

      /* Surface */

      color: #ffffff;

      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .hero-button {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        margin: 0px auto;
        border: none;
        background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
        border-radius: 8px;
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height, or 100% */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Surface */

        color: #ffffff;
        padding: 11px 37px;
      }
    }
  }
  .hero-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
    z-index: -1;
    height: 150px;
    object-fit: cover;
  }
}

// /////////////////////////Media Queries//////////////////////////////

@media only screen and (max-width: 1440px) {
  .Hero {
    .Hero-main {
      padding: 150px 0px 200px 0px;

      h1 {
        font-size: 50px;
        line-height: 50px;
      }
      p {
        font-size: 24px;
        line-height: 28px;
      }
      .hero-button {
        button {
          margin: 0px auto;
          border: none;
          background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
          border-radius: 8px;
          font-family: "Bakbak One";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          /* identical to box height, or 100% */

          letter-spacing: 0.1em;
          text-transform: uppercase;

          /* Surface */

          color: #ffffff;
          padding: 11px 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Hero {
    .hero-footer {
      display: none;
    }
    .Hero-main {
      padding: 80px 30px 100px 30px;
      .mob {
        display: none;
      }
      h1 {
        font-size: 25px;
        line-height: 25px;
      }
      p {
        font-size: 16px;
        line-height: 16px;
      }
      .hero-button {
        button {
          margin: 0px auto;
          border: none;
          background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
          border-radius: 8px;
          font-family: "Bakbak One";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          /* identical to box height, or 100% */

          letter-spacing: 0.1em;
          text-transform: uppercase;

          /* Surface */

          color: #ffffff;
          padding: 10px 20px;
        }
      }
    }
  }
}
