.signUp {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url("../../Images//robotBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.signUp .main-view {
  padding: 0px 50px;
  width: 35%;
  align-self: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 50px;
  margin-left: 609px;
  margin-right: 609px;
  padding-top: 46px;
  padding-bottom: 80px;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 7px;
}

.signUp .main-view .main-view-one {
  /* height: 30%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.signUp .main-view .main-view-two {
  /* height: 40%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}
.signUp .main-view .main-view-two .inputDiv {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 10px;
  border: 2px solid #ff7dfa;
  margin-top: 24px;
  background: linear-gradient(
    107.21deg,
    rgba(98, 114, 201, 0.26) 2.84%,
    rgba(163, 178, 254, 0.06) 73.23%
  );
  mix-blend-mode: normal;
  opacity: 0.7;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 32.5px;
  width: 65%;
}

.signUp .main-view .main-view-two .inputSection {
  width: 100%;
  height: 45px;
  color: white;
  background: transparent;
  border: none;
  margin-left: 5px;
}
.signUp .main-view .main-view-two .inputSection:focus-visible {
  outline: none;
}

.signUp .main-view .main-view-two .inputSection::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  margin-left: 10px;

  /* Surface */
  color: white; /* Change the color to your desired value */
}

.signUp .main-view .main-view-two .button {
  height: 50px;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
  border-radius: 32.5px;
  margin-top: 50px;
  border: none;
}

.signUp .main-view .main-view-two .button .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.1em;
  margin: 0;
  /* Surface */
  color: #ffffff;
}
.signUp .main-view .main-view-two .textOne {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  margin-top: 20px;
  /* Surface */
  display: flex;
  text-align: center;
  justify-content: center;
  color: #ffffff;
}

.signUp .main-view .main-view-three {
  /* height: 30%; */
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  justify-content: center;
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.signUp .main-view .main-view-three .buttonOne {
  height: 50px;
  width: 85%;
  background: #ffffff;
  border-radius: 32.5px;
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}

.signUp .main-view .main-view-three .buttonTwo {
  height: 50px;
  width: 85%;
  background: #1877f2;
  border-radius: 32.5px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}
.signUp .main-view .main-view-three .buttonOne:focus-visible {
  outline: none;
}
.signUp .main-view .main-view-three .buttonTwo:focus-visible {
  outline: none;
}

.signUp .main-view .main-view-three .buttonOne .textGoogle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.1em;
  margin: 0;
  width: 80%;
  color: #343434;
}

.signUp .main-view .main-view-three .buttonTwo .textFacebook {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.1em;
  margin: 0;
  width: 80%;
  /* Surface */
  color: #ffffff;
}

.signUp .main-view .main-view-three .textOne {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  /* Surface */
  text-align: center;
  color: #ffffff;
}
.signUp .main-view .main-view-two .textTwo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  margin-top: 30px;
  /* Surface */
  text-align: center;
  color: #ffffff;
}

.signUp .main-view .main-view-two .textTwo .textTwoA {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  margin-top: 30px;
  /* Surface */
  color: #57d7ee;
  text-decoration: none;
}

@media screen and (max-width: 1400px) {
  .signUp .main-view {
    padding: 0px 50px;
    width: 50%;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 50px;
    margin-left: 609px;
    margin-right: 609px;
    padding-top: 20px;
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 7px;
  }
  .signUp .main-view .main-view-two {
    /* height: 40%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .signUp .main-view .main-view-three {
    /* height: 30%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 650px) {
  .signUp .main-view {
    padding: 0px 50px;
    width: 80%;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 177px;
    margin-bottom: 77px;
    margin-left: 609px;
    margin-right: 609px;
    padding-top: 46px;
    padding-bottom: 333px;
    padding-left: 30px;
    padding-right: 30px;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 7px;
  }
}

@media screen and (max-width: 405px) {
  .signUp .main-view {
    padding: 0px 50px;
    width: 80%;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 177px;
    margin-bottom: 77px;
    margin-left: 609px;
    margin-right: 609px;
    padding-top: 46px;
    padding-bottom: 333px;
    padding-left: 30px;
    padding-right: 30px;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 7px;
  }

  .signUp .main-view .main-view-three .buttonOne .textGoogle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 33px;
    letter-spacing: 0.1em;
    margin: 0;
    width: 85%;
    color: #343434;
  }

  .signUp .main-view .main-view-three .buttonTwo .textFacebook {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 33px;
    letter-spacing: 0.1em;
    margin: 0;
    width: 85%;
    /* Surface */
    color: #ffffff;
  }
  .signUp .main-view .main-view-two .textOne {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.1em;
    margin-top: 20px;
    /* Surface */
    display: flex;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }

  .signUp .main-view .main-view-two .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.1em;
    margin-top: 30px;
    /* Surface */
    text-align: center;
    color: #ffffff;
  }

  .signUp .main-view .main-view-two .textTwo .textTwoA {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.1em;
    margin-top: 30px;
    /* Surface */
    color: #57d7ee;
    text-decoration: none;
  }

  .signUp .main-view .main-view-three .textTwo .textTwoA {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.1em;
    margin-top: 30px;
    /* Surface */
    color: #57d7ee;
    text-decoration: none;
  }

  .signUp .main-view .main-view-two .textOne {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.1em;
    margin-top: 20px;
    /* Surface */
    display: flex;
    text-align: center;
    justify-content: end;
    color: #ffffff;
  }

  .signUp .main-view .main-view-two .inputSection::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 27px;
    letter-spacing: 0.1em;
    margin-left: 10px;

    /* Surface */
    color: white; /* Change the color to your desired value */
  }
  .signUp .main-view .main-view-two .inputDiv {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 10px;
    border: 2px solid #ff7dfa;
    margin-top: 24px;
    background: linear-gradient(
      107.21deg,
      rgba(98, 114, 201, 0.26) 2.84%,
      rgba(163, 178, 254, 0.06) 73.23%
    );
    mix-blend-mode: normal;
    opacity: 0.7;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 32.5px;
    width: 100%;
  }
}
