.Revolution {
  padding: 276px 160px 388px 160px;
  height: 100%;
  background: url(/Images/Revolutionback.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .Revolution-main {
    h1 {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 56px;
      line-height: 78px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      background: linear-gradient(95.49deg, #ffbe16 -5.24%, #ff7a1a 114.63%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin: 0px;
    }
    p {
      margin: 36px 0px 100px 0px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 38px;
      letter-spacing: 0.1em;

      /* Surface */

      color: #ffffff;
    }
    button {
      background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
      border-radius: 8px;
      border: none;
      padding: 11px 30px 10px 44px;
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      /* identical to box height, or 100% */

      letter-spacing: 0.1em;
      text-transform: uppercase;

      /* Surface */

      color: #ffffff;
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1366px) {
  .Revolution {
    padding: 100px 100px 150px 100px;
    .Revolution-main {
      h1 {
        font-size: 40px;
        line-height: 50px;
      }
      p {
        margin: 30px 0px 60px 0px;
        font-size: 22px;
        line-height: 32px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Revolution {
    padding: 90px 30px 90px 30px;
    .Revolution-main {
      h1 {
        font-size: 25px;
        line-height: 30px;
        .mob {
          display: none;
        }
      }
      p {
        margin: 20px 0px 30px 0px;
        font-size: 16px;
        line-height: 20px;
        .mob {
          display: none;
        }
      }
      button {
        padding: 11px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .Revolution {
    padding: 90px 30px 90px 30px;
    .Revolution-main {
      h1 {
        font-size: 22px;
        line-height: 25px;
      }
    }
  }
}
