.Immersive {
  background: url(/Images/immersive.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 135px 130px 291px 160px;
  .Immersive-div {
    display: flex;
    column-gap: 50px;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 40%;
      h1 {
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 70px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin: 0px;
      }
      p {
        margin: 30px 0px 0px 0px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: 0.1em;

        /* Surface */

        color: #ffffff;
      }
    }
    .right {
      width: 60%;
      .card {
        width: 100%;
        border: none;
        background: transparent;
        video {
          width: 100%;
          padding: 3px;
          border-radius: 10px;
          background: linear-gradient(180deg, #77d6ff 0%, #cb45ff 100%);

          // z-index: 999;
        }
        .volume {
          position: absolute;
          bottom: 10px;
          right: 10px;
          cursor: pointer;
        }
        .mute {
          position: absolute;
          bottom: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1700px) {
  .Immersive {
    .Immersive-div {
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 1480px) {
  .Immersive {
    .Immersive-div {
      .left {
        h1 {
          font-size: 45px;
          line-height: 55px;
          // .mob {
          //   display: none;
          // }
        }
        p {
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .Immersive {
    padding: 100px 100px 150px 100px;
    .Immersive-div {
      .left {
        h1 {
          font-size: 28px;
          line-height: 35px;
        }
        p {
          font-size: 18px;
          line-height: 22px;
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Immersive {
    padding: 30px;
    .Immersive-div {
      flex-direction: column;
      .left {
        width: 100%;
        h1 {
          font-size: 26px;
          line-height: 30px;
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .right {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
