.Participate {
  background: #040617;
  padding: 145px 104px 145px 160px;
  .Participate-main {
    display: flex;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    .left {
      width: 50%;
      position: relative;
      .left-back {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        background: rgba(157, 48, 208, 0.25);
        filter: blur(250px);
        z-index: 1;
      }
      //   background: rgba(157, 48, 208, 0.25);
      //   filter: blur(250px);
      h1 {
        margin: 0px 0px 36px 0px;
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 70px;
        /* identical to box height */

        // text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      p {
        margin: 0px 0px 36px 0px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: 0.1em;

        /* Surface */

        color: #ffffff;
      }
      button {
        cursor: pointer;
        background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
        border-radius: 8px;
        padding: 5px 31px 7px 11px;
        border: none;
        width: 330px;
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .right {
      position: relative;
      width: 50%;

      img {
        width: 100%;
      }
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1600px) {
  .Participate {
    .Participate-main {
      .left {
        h1 {
          font-size: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .Participate {
    padding: 100px 100px 150px 100px;

    .Participate-main {
      .left {
        h1 {
          font-size: 35px;
          line-height: 50px;
          margin-bottom: 30px;
        }
        p {
          font-size: 18px;
          line-height: 22px;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 0px;
          font-size: 18px;
          line-height: 20px;
          width: 250px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1125px) {
  .Participate {
    padding: 100px 100px 150px 100px;

    .Participate-main {
      .left {
        h1 {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Participate {
    padding: 30px;

    .Participate-main {
      flex-direction: column;
      .left {
        width: 100%;
        h1 {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 443px) {
  .Participate {
    .Participate-main {
      .left {
        h1 {
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
  }
}
@media only screen and (max-width: 380px) {
  .Participate {
    .Participate-main {
      .left {
        h1 {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
