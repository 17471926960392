.Partners {
  background: #050519;
  padding: 179px 160px;
  .Partners-main {
    h1 {
      margin: 0px 0px 50px 0px;
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 70px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .icons {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 122px;
      row-gap: 100px;
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1366px) {
  .Partners {
    padding: 100px 100px 150px 100px;
    .Partners-main {
      .icons {
        row-gap: 50px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Partners {
    padding: 30px;
    .Partners-main {
      h1 {
        margin-bottom: 20px;
        font-size: 35px;
      }
      .icons {
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        img {
          width: 100%;
        }
      }
    }
  }
}
