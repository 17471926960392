.Setting {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  background-image: url("../../Images//robotBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.Setting .Setting-view-Setting {
  width: 15.9375%;
  .show {
    transform: translateX(0);
  }
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );

  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
.Setting .Setting-view-two-one-button-Back {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
  display: none;
}
.Setting .Setting-view-two-one-button {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
}
.Setting .Setting-view-two-one-button-Back .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}
.Setting .Setting-view-two-one-button .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}
.Setting .Setting-view-main {
  width: 84.1725%;
  display: flex;
  flex-direction: column;
  padding: 0px 160px 164px 34px;

  /* Note: backdrop-filter has minimal browser support */
}

.Setting .Setting-view-main .Setting-view-main-first {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
  padding-bottom: 30px;
  /* Note: backdrop-filter has minimal browser support */
}

.Setting .Setting-view-main .Setting-view-main-first .rightDiv {
  display: flex;
  align-content: flex-end;
  align-self: flex-end;
  column-gap: 115px;
}
.Setting .Setting-view-main .Setting-view-main-first .rightDiv .nav-btn-one {
  padding: 8px 40px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
  border-radius: 7px;
  border: none;
}

.Setting .Setting-view-main .Setting-view-main-first .nav-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Setting .Setting-view-main .Setting-view-main-first .drawerIcons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none !important;
}

.Setting .Setting-view-main-first .nav-div-div {
  border-radius: 30px;
  margin-right: 8px;
}

.Setting .Setting-view-main-first .nav-div .nav-div-link-tag {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  text-decoration: none;
}

.Setting .Setting-view-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* Note: backdrop-filter has minimal browser support */
}

.Setting .Setting-view-main .Setting-view-main-second {
  /* height: 30%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 165px;
  padding-left: 20px;
  padding-right: 20px;
}

.Setting .Setting-view-main .Setting-view-main-second .heading {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  border: 1px solid #23a3ff;
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 40px;
  border-radius: 7px;
  display: flex;
}
.Setting .Setting-view-main .Setting-view-main-second .heading .text {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  margin: 0;
}

.Setting .Setting-view-main .Setting-view-main-second .comingsoon {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  border: 1px solid #23a3ff;
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding-top: 35px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-top: 15px;
}

.Setting .Setting-view-main .Setting-view-main-second .comingsoon .left {
  width: 35%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv {
  background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
  opacity: 0.8;
  border-radius: 8px;
  padding-top: 26px;
  padding-bottom: 17px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .first {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .first
  .status {
  height: 70px;
  width: 70px;
  border-radius: 50px;
  border: 3px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .first
  .status
  .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 126% */

  text-align: center;
  align-self: center;
  letter-spacing: 0.1em;
  margin: 0;
  /* Surface */
  color: #ffffff;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .first
  .profileInfo {
  width: 70%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .first
  .profileInfo
  .textOne {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */

  text-align: left;
  letter-spacing: 0.1em;
  margin: 0;
  /* Surface */

  color: #ffffff;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .first
  .profileInfo
  .textTwo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  text-align: left;
  letter-spacing: 0.1em;
  margin-top: 10px;
  color: #afafaf;
  margin: 0;
  /* Surface */
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .secondFbutton {
  background-color: white;
  border-radius: 22.5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 46px;
  width: 65%;
  align-self: center;
  border: none;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .firstDiv
  .secondFbutton
  .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */

  text-align: center;
  letter-spacing: 0.1em;
  margin: 0;
  color: #3c3c3c;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .secondDiv {
  display: flex;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #23a3ff;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 7px;
  margin-top: 39px;
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .left
  .thirdDiv {
  display: flex;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #23a3ff;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 7px;
  margin-top: 16px;
}

.Setting .Setting-view-main .Setting-view-main-second .comingsoon .left .text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  /* identical to box height, or 126% */

  text-align: start;
  letter-spacing: 0.1em;

  /* Surface */
  margin: 0;
  width: 90%;
  padding-left: 5px;
  color: #ffffff;
}

.Setting .Setting-view-main .Setting-view-main-second .comingsoon .right {
  width: 65%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .right
  .headings {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  /* Surface */
  color: #ffffff;
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .right
  .InputDivMain {
  /* height: 40%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid #23a3ff;
  background: linear-gradient(
    110.14deg,
    rgba(104, 124, 227, 0.26) -23.18%,
    rgba(72, 95, 211, 0.06) 95.56%
  );
  border-radius: 7px;
  margin-top: 3px;
}
.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .right
  .InputDivMain
  .inputDiv {
  /* Note: backdrop-filter has minimal browser support */
  width: 100%;
  border: none;
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .right
  .InputDivMain
  .inputDiv
  .inputSection {
  width: 100%;
  height: 45px;
  color: white;
  background: transparent;
  border: none;
  padding-left: 20px;
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .right
  .InputDivMain
  .inputDiv
  .inputSection::placeholder {
  color: white;
  font-size: 16px;
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .right
  .twitterDiv {
  background: linear-gradient(
    110.14deg,
    rgba(104, 124, 227, 0.26) -23.96%,
    rgba(72, 95, 211, 0.06) 94.78%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #23a3ff;
  border-radius: 7px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;

  .firstDiv {
    display: flex;
    align-items: center;

    .text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 0.1em;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 10px;
      /* Surface */
      color: #ffffff;
    }
  }

  .connect {
    background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
    border-radius: 8px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    .text {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 126% */

      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 0;
      /* Surface */

      color: #ffffff;
    }
  }
}

.Setting
  .Setting-view-main
  .Setting-view-main-second
  .comingsoon
  .right
  .twoButtonDiv {
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 7px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 32px;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding-right: 10px;

  .changesDiscard {
    background: linear-gradient(269.43deg, #d61717 -3.87%, #e43a96 106.72%);
    border-radius: 8px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    margin-right: 10px;
    .text {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 126% */

      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 0;
      /* Surface */

      color: #ffffff;
    }
  }
  .changesSave {
    background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
    border-radius: 8px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    .text {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 126% */

      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 0;
      /* Surface */

      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1500px) {
  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .left
    .firstDiv
    .secondFbutton {
    background-color: white;
    border-radius: 22.5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 46px;
    width: 100%;
    align-self: center;
    border: none;
  }
}

@media screen and (max-width: 1200px) {
  .Setting .Setting-view-two-one-button-Back {
    /* height: 30%; */
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .Setting .Setting-view-two-one-button-Back {
    /* height: 30%; */
    display: none;
  }

  .Setting .Setting-view-main .Setting-view-main-second .heading .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 114% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    margin: 0;
  }

  .Setting .Setting-view-main .Setting-view-main-second .comingsoon .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    /* identical to box height */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;
    background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .Setting .Setting-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 126% */
    width: 80%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }
  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .left
    .firstDiv
    .secondFbutton
    .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.01em;
    margin: 0;
    color: #3c3c3c;
  }
  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .left
    .firstDiv
    .first
    .profileInfo
    .textOne {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: left;
    letter-spacing: 0.1em;
    margin: 0;
    /* Surface */

    color: #ffffff;
  }

  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .left
    .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: center;
    letter-spacing: 0.01em;

    /* Surface */
    margin: 0;
    width: 90%;
    padding-left: 5px;
    color: #ffffff;
  }

  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .right
    .twitterDiv {
    background: linear-gradient(
      110.14deg,
      rgba(104, 124, 227, 0.26) -23.96%,
      rgba(72, 95, 211, 0.06) 94.78%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #23a3ff;
    border-radius: 7px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;

    .firstDiv {
      display: flex;
      align-items: center;

      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: 0.1em;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 10px;
        /* Surface */
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .Setting .Setting-view-main .Setting-view-main-first .rightDiv {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-end;
    row-gap: 10px;
    justify-content: space-between;
  }
  .Setting .Setting-view-main .Setting-view-main-second .heading .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height, or 114% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    margin: 0;
  }

  .Setting .Setting-view-main .Setting-view-main-second .comingsoon .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;
    background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
  }
  .Setting .Setting-view-main .Setting-view-main-first .drawerIcons {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block !important;
  }

  .Setting .Setting-view-main {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    /* Note: backdrop-filter has minimal browser support */
  }
  .Setting .Setting-view-main .Setting-view-main-first {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
    /* Note: backdrop-filter has minimal browser support */
  }

  .Setting .Setting-view-main .Setting-view-main-second {
    /* height: 30%; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Setting .Setting-view-main .Setting-view-main-second .heading {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    padding-top: 23px;
    padding-bottom: 23px;

    border-radius: 7px;
  }

  .Setting .Setting-view-main .Setting-view-main-second .comingsoon {
    width: 100%;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-top: 15px;
  }
  .Setting .Setting-view-main .Setting-view-main-first .nav-div {
    width: 65%;
    display: flex;
    align-self: flex-end;
  }
  .Setting .Setting-view-main-first .nav-div .nav-div-link-tag {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    text-decoration: none;
  }
  .Setting .Setting-view-main .Setting-view-main-first .rightDiv .nav-btn-one {
    padding: 8px 0px;
    width: 55%;
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
    border-radius: 7px;
    border: none;
    align-self: flex-end;
    margin-right: 10px;
  }

  .Setting .Setting-view-Setting {
    /* height: 30%; */
    width: 50%;
    height: 60%;
    background-color: #000116;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    transition: 0.5s ease;
    transform: translateX(-1000px);
    z-index: 1;
  }

  .Setting .Setting-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 15px;
    /* identical to box height, or 126% */
    width: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }

  .Setting .Setting-view-two-one-button-Back .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 15px;
    /* identical to box height, or 126% */
    width: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }
  .Setting .Setting-view-two-one-button-Back {
    display: block;
  }

  .Setting .Setting-view-main .Setting-view-main-second .comingsoon {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    border: 1px solid #23a3ff;
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    padding-top: 35px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-top: 15px;
  }
  .Setting .Setting-view-main .Setting-view-main-second .comingsoon .left {
    width: 95%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .left
    .firstDiv
    .first
    .profileInfo
    .textOne {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    /* identical to box height, or 126% */

    text-align: left;
    letter-spacing: 0.1em;
    margin: 0;
    /* Surface */

    color: #ffffff;
  }
  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .left
    .firstDiv
    .first
    .profileInfo
    .textTwo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 160% */
    text-align: left;
    letter-spacing: 0.1em;
    margin-top: 10px;
    color: #afafaf;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-top: 10px;
    /* Surface */
  }
  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .left
    .firstDiv
    .secondFbutton {
    background-color: white;
    border-radius: 22.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 46px;
    width: 70%;
    align-self: center;
    border: none;
  }
  .Setting .Setting-view-main .Setting-view-main-second .comingsoon .right {
    width: 95%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .right
    .twitterDiv {
    background: linear-gradient(
      110.14deg,
      rgba(104, 124, 227, 0.26) -23.96%,
      rgba(72, 95, 211, 0.06) 94.78%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #23a3ff;
    border-radius: 7px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;

    .firstDiv {
      display: flex;
      align-items: center;

      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 34px;
        letter-spacing: 0.01em;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 10px;
        /* Surface */
        color: #ffffff;
      }
    }
    .connect {
      width: 40%;
      .text {
        font-size: 11px;
      }
    }
  }
  .Setting
    .Setting-view-main
    .Setting-view-main-second
    .comingsoon
    .right
    .twoButtonDiv {
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 7px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 32px;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    padding-right: 10px;

    .changesDiscard {
      background: linear-gradient(269.43deg, #d61717 -3.87%, #e43a96 106.72%);
      border-radius: 8px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 7px;
      padding-bottom: 7px;
      border: none;
      .text {
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 126% */

        letter-spacing: 0.01em;
        text-transform: uppercase;
        margin: 0;
        /* Surface */

        color: #ffffff;
      }
    }
    .changesSave {
      background: linear-gradient(269.16deg, #fd00f3 12.42%, #22a2ff 91.8%);
      border-radius: 8px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 7px;
      padding-bottom: 7px;
      border: none;
      .text {
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 126% */

        letter-spacing: 0.01em;
        text-transform: uppercase;
        margin: 0;
        /* Surface */

        color: #ffffff;
      }
    }
  }
}
