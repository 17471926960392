.Overview {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  background-image: url("../../Images//robotBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.Overview .Overview-view-Overview {
  width: 15.9375%;
  .show {
    transform: translateX(0);
  }
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );

  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  transition: transform 0.5s ease;
  .show {
    transform: translateX(0);
  }
}
.Overview .Overview-view-two-one-button-Back {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
  display: none;
}
.Overview .Overview-view-two-one-button {
  /* height: 30%; */
  width: 100%;
  height: 6%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  border: none;
}
.Overview .Overview-view-two-one-button-Back .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}
.Overview .Overview-view-two-one-button .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 126% */
  width: 80%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  /* Surface */
  margin: 0;
  color: #ffffff;
  padding-left: 15px;
}
.Overview .Overview-view-main {
  width: 84.1725%;
  display: flex;
  flex-direction: column;
  padding: 0px 160px 164px 34px;
  /* Note: backdrop-filter has minimal browser support */
}

.Overview .Overview-view-main .Overview-view-main-first {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
  padding-bottom: 30px;
  /* Note: backdrop-filter has minimal browser support */
}

.Overview .Overview-view-main .Overview-view-main-first .rightDiv {
  display: flex;
  align-content: flex-end;
  align-self: flex-end;
  column-gap: 115px;
}
.Overview .Overview-view-main .Overview-view-main-first .rightDiv .nav-btn-one {
  padding: 8px 40px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
  border-radius: 7px;
  border: none;
}

.Overview .Overview-view-main .Overview-view-main-first .nav-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Overview .Overview-view-main .Overview-view-main-first .drawerIcons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none !important;
}

.Overview .Overview-view-main-first .nav-div-div {
  border-radius: 30px;
  margin-right: 8px;
}

.Overview .Overview-view-main-first .nav-div .nav-div-link-tag {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  text-decoration: none;
}

.Overview .Overview-view-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    106.9deg,
    rgba(215, 0, 251, 0.2) 2.82%,
    rgba(65, 45, 147, 0.2) 95.31%
  );
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* Note: backdrop-filter has minimal browser support */
}

.Overview .Overview-view-main .Overview-view-main-second {
  /* height: 30%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 165px;
  padding-left: 20px;
  padding-right: 20px;
}

.Overview .Overview-view-main .Overview-view-main-second .heading {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  border: 1px solid #23a3ff;
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 40px;
  border-radius: 7px;
}

.Overview .Overview-view-main .Overview-view-main-second .comingsoon {
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  border: 1px solid #23a3ff;
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  padding-top: 225px;
  padding-bottom: 397px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-top: 15px;
}
.Overview .Overview-view-main .Overview-view-main-second .comingsoon .text {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 70px;
  /* identical to box height */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;
  background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.Overview .Overview-view-main .Overview-view-main-second .heading .text {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 114% */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* Surface */
  color: #ffffff;
  margin: 0;
}

@media screen and (max-width: 1380px) {
  .Overview .Overview-view-two-one-button-Back {
    /* height: 30%; */
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .Overview .Overview-view-two-one-button-Back {
    /* height: 30%; */
    display: none;
  }

  .Overview .Overview-view-main .Overview-view-main-second .heading .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 114% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    margin: 0;
  }

  .Overview .Overview-view-main .Overview-view-main-second .comingsoon .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    /* identical to box height */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;
    background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .Overview .Overview-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 126% */
    width: 80%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }
}

@media screen and (max-width: 830px) {
  .Overview .Overview-view-main .Overview-view-main-first .rightDiv {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-end;
    row-gap: 10px;
    justify-content: space-between;
  }
  .Overview .Overview-view-main .Overview-view-main-second .heading .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height, or 114% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    margin: 0;
  }

  .Overview .Overview-view-main .Overview-view-main-second .comingsoon .text {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;
    background: linear-gradient(96.35deg, #64f6ff -15.31%, #e31cff 117.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
  }
  .Overview .Overview-view-main .Overview-view-main-first .drawerIcons {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block !important;
  }

  .Overview .Overview-view-main {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    /* Note: backdrop-filter has minimal browser support */
  }
  .Overview .Overview-view-main .Overview-view-main-first {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
    /* Note: backdrop-filter has minimal browser support */
  }

  .Overview .Overview-view-main .Overview-view-main-second {
    /* height: 30%; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Overview .Overview-view-main .Overview-view-main-second .heading {
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    padding-top: 23px;
    padding-bottom: 23px;

    border-radius: 7px;
  }

  .Overview .Overview-view-main .Overview-view-main-second .comingsoon {
    width: 100%;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-top: 15px;
  }
  .Overview .Overview-view-main .Overview-view-main-first .nav-div {
    width: 65%;
    display: flex;
    align-self: flex-end;
  }
  .Overview .Overview-view-main-first .nav-div .nav-div-link-tag {
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 100% */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    text-decoration: none;
  }
  .Overview
    .Overview-view-main
    .Overview-view-main-first
    .rightDiv
    .nav-btn-one {
    padding: 8px 0px;
    width: 50%;
    font-family: "Bakbak One";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 100% */
    letter-spacing: 0.01em;
    text-transform: uppercase;
    /* Surface */
    color: #ffffff;
    background: linear-gradient(106.9deg, #d700fb 2.82%, #412d93 95.31%);
    border-radius: 7px;
    border: none;
    align-self: flex-end;
    margin-right: 10px;
  }

  .Overview .Overview-view-Overview {
    /* height: 30%; */
    width: 50%;
    height: 60%;
    background-color: #000116;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    transition: 0.5s ease;
    transform: translateX(-1000px);
    z-index: 1;
  }

  .Overview .Overview-view-two-one-button .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    /* identical to box height, or 126% */
    width: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }

  .Overview .Overview-view-two-one-button-Back .buttonText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 15px;
    /* identical to box height, or 126% */
    width: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    /* Surface */
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
  }
  .Overview .Overview-view-two-one-button-Back {
    display: block;
  }
}
