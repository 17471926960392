.Footer {
  padding: 100px 160px 280px 160px;
  background: #181a29;
  .Footer-main {
    display: flex;
    column-gap: 90px;
    row-gap: 50px;
    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 60px;
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        /* or 150% */

        display: flex;
        align-items: center;

        color: #ffffff;
      }
      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        color: #ffffff;
      }
    }
    .center {
      width: 20%;
      h6 {
        margin: 0px 0px 39px 0px;
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        /* or 29px */

        /* Solid/Single/White */

        color: #ffffff;
      }
      ul {
        padding: 0px;
        li {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #ffffff;
          margin-bottom: 25px;
          margin-left: 35px;
          position: relative;
          &::before {
            content: url(/Images/ic-arrow-circle-right.svg);
            top: 4px;
            left: -35px;
            position: absolute;
          }
          &::marker {
            content: "";
          }
        }
      }
    }
    .right {
      width: 30%;
      h5 {
        font-family: "Bakbak One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        color: #ffffff;
      }
      .icons {
        margin-top: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

//////////////////////////// Media Queries/////////////////////////

@media only screen and (max-width: 1366px) {
  .Footer {
    padding: 100px 100px 150px 100px;
    background: #181a29;
    .Footer-main {
      display: flex;
      column-gap: 40px;
      .left {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .Footer {
    padding: 60px 100px 100px 100px;
    .Footer-main {
      column-gap: 30px;
      .left {
        width: 45%;
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
      .center {
        width: 25%;
      }
      .right {
        width: 30%;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Footer {
    padding: 60px 30px;
    .Footer-main {
      flex-direction: column;
      row-gap: 20px;
      .left {
        width: 100%;
        row-gap: 20px;
        a {
          width: 80% !important;
          img {
            width: 100%;
          }
        }
        p {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0px;
        }
      }
      .center {
        width: 100%;
        h6 {
          margin-bottom: 20px;
        }
        ul {
          li {
            margin-bottom: 10px;
            font-size: 16px;
          }
        }
      }
      .right {
        width: 100%;
        .icons {
          margin-top: 20px;
        }
      }
    }
  }
}
