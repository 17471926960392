* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  background: black;
}
@font-face {
  font-family: "Game Of Squids";
  src: url(/public/Font/GameOfSquids-1GMVL.ttf);
}
@font-face {
  font-family: "Century Gothic";
  src: url(/public/Font/GOTHIC.TTF);
}
input:focus-visible {
  outline: none;
}

/* * {
  outline: 2px solid lime !important;
} */
