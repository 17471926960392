.robot {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url("../../Images//robotBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.robot .main-view {
  padding: 0px 50px;
  width: 35%;
  align-self: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 50px;
  margin-left: 609px;
  margin-right: 609px;
  padding-top: 46px;
  padding-bottom: 281px;
  background: linear-gradient(
    107.21deg,
    rgba(104, 124, 227, 0.26) 2.84%,
    rgba(72, 95, 211, 0.06) 73.23%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 7px;
}

.robot .main-view .main-view-one {
  /* height: 30%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.robot .main-view .main-view-two {
  /* height: 40%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.robot .main-view .main-view-two-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Surface */

  color: #ffffff;
}

.robot .main-view .main-view-two-textTwo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  /* line-height: 15px; */
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  margin: 0px;
  /* Surface */

  color: #ffffff;
}

.robot .main-view .main-view-three {
  /* height: 30%; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding-left: 25px;
  padding-right: 25px;
}

@media screen and (max-width: 1400px) {
  .robot .main-view {
    padding: 0px 50px;
    width: 35%;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 50px;
    margin-left: 609px;
    margin-right: 609px;
    padding-top: 20px;
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 7px;
  }
  .robot .main-view .main-view-two {
    /* height: 40%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .robot .main-view .main-view-three {
    /* height: 30%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 650px) {
  .robot .main-view {
    padding: 0px 50px;
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 177px;
    margin-bottom: 77px;
    margin-left: 609px;
    margin-right: 609px;
    padding-top: 46px;
    padding-bottom: 333px;
    padding-left: 30px;
    padding-right: 30px;
    background: linear-gradient(
      107.21deg,
      rgba(104, 124, 227, 0.26) 2.84%,
      rgba(72, 95, 211, 0.06) 73.23%
    );
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 7px;
  }
}
